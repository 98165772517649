exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-buy-index-js": () => import("./../../../src/pages/buy/index.js" /* webpackChunkName: "component---src-pages-buy-index-js" */),
  "component---src-pages-buy-tokens-index-js": () => import("./../../../src/pages/buy-tokens/index.js" /* webpackChunkName: "component---src-pages-buy-tokens-index-js" */),
  "component---src-pages-confirm-index-js": () => import("./../../../src/pages/confirm/index.js" /* webpackChunkName: "component---src-pages-confirm-index-js" */),
  "component---src-pages-creators-js": () => import("./../../../src/pages/creators.js" /* webpackChunkName: "component---src-pages-creators-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-evolving-index-js": () => import("./../../../src/pages/evolving/index.js" /* webpackChunkName: "component---src-pages-evolving-index-js" */),
  "component---src-pages-fans-js": () => import("./../../../src/pages/fans.js" /* webpackChunkName: "component---src-pages-fans-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-js": () => import("./../../../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-lulu-99-js": () => import("./../../../src/pages/lulu99.js" /* webpackChunkName: "component---src-pages-lulu-99-js" */),
  "component---src-pages-oauth-2-redirect-index-js": () => import("./../../../src/pages/oauth2/redirect/index.js" /* webpackChunkName: "component---src-pages-oauth-2-redirect-index-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-pages-rdjavi-index-js": () => import("./../../../src/pages/rdjavi/index.js" /* webpackChunkName: "component---src-pages-rdjavi-index-js" */),
  "component---src-pages-recovery-index-js": () => import("./../../../src/pages/recovery/index.js" /* webpackChunkName: "component---src-pages-recovery-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-signin-index-js": () => import("./../../../src/pages/signin/index.js" /* webpackChunkName: "component---src-pages-signin-index-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-success-index-js": () => import("./../../../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-thedonato-index-js": () => import("./../../../src/pages/thedonato/index.js" /* webpackChunkName: "component---src-pages-thedonato-index-js" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */),
  "component---src-pages-verify-index-js": () => import("./../../../src/pages/verify/index.js" /* webpackChunkName: "component---src-pages-verify-index-js" */),
  "component---src-pages-watch-2-earn-js": () => import("./../../../src/pages/watch2earn.js" /* webpackChunkName: "component---src-pages-watch-2-earn-js" */),
  "component---src-pages-xcad-buy-js": () => import("./../../../src/pages/xcad-buy.js" /* webpackChunkName: "component---src-pages-xcad-buy-js" */),
  "component---src-pages-xgrant-js": () => import("./../../../src/pages/xgrant.js" /* webpackChunkName: "component---src-pages-xgrant-js" */)
}

